// Fill and stroke colors for processed job polygons
const POLYGON_STYLES = {
  working_area: { fillColor: '#ffcc00', strokeColor: '#00000000'},
  working_area_outer_perimeter: { fillColor: '#00000000', strokeColor: '#d50000' },
  mowing_area: { fillColor: '#5fcc00', strokeColor: '#ffcc00' },
  keep_out_zone_working_areas: { fillColor: '#ffcc00', strokeColor: '#00000000' },
  keep_out_zones: { fillColor: '#ff0000ff', strokeColor: '#d50000' },
}

// Logic for rendering a job boundary on a Google Map as a Polygon.
export default class ProcessedJob {
  constructor(processedJob) {
    this.jobId = processedJob.job_id
    // Contains an array of processed job polygons
    this.processedJobPolygons = []

    let _this = this

    $(window).on("map-rendered", function(event) {
      _this.renderProcessedJob(processedJob.areas)
    })

    if (map.gmap) {
      _this.renderProcessedJob(processedJob.areas)
    }
  }

  // Renders polygons for a processed job. Each polygon type is included in the `POLYGON_STYLES` const.
  renderProcessedJob(areas) {
    map.currentJob?.hide()
    this.hide()

    Object.entries(areas).forEach(([type, paths]) => {
      if (!paths) return
      if (type == 'keep_out_zone_working_areas' || type == 'keep_out_zones') {
        paths.forEach((path) => this.processedJobPolygon(type, path))
      } else {
        this.processedJobPolygon(type, paths)
      }
    })
  }

  // Hides and removed processed job polygons.
  hide() {
    this.processedJobPolygons.forEach(polygon => polygon.setMap(null))
    this.processedJobPolygons = []
  }

  // Styles and renders each type of processed job polygon based on matching the type to a style using `POLYGON_STYLES`.
  processedJobPolygon(type, paths) {
    let styles = POLYGON_STYLES[type]
    let polygon = new google.maps.Polygon({
      map: map.gmap,
      paths: paths,
      fillColor: styles.fillColor,
      strokeColor: styles.strokeColor,
      strokeOpacity: 0.7,
      fillOpacity: 0.3,
      strokeWeight: 3,
      zIndex: 1001,
    })
    this.processedJobPolygons.push(polygon)
  }
}
