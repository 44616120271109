import RecordingProgress from "custom/companion/recording_progress"

// Logic for rendering record and repeat, keep-out, and go-to features
export default class MappingMenu {
  constructor() {
    // When a keep-out zone is being mapped, a google maps polyline is used to display progress on map
    this.recordingProgress = null
    this.recordingTimer = null
    // Gmap marker placed on map to represent a GoTo location
    this.goToMarker = null
    // Click listener used to get lat/long location for GoTo
    this.goToListener = null

    this.addListeners()
  }

  // Create, update, or hide keep-out or record and repeat progress polyline
  renderRecordingProgress(content) {
    if (content.isRecording) {
      if (this.recordingProgress) {
        this.recordingProgress.update(content.points)
      } else {
        this.recordingProgress = new RecordingProgress(content)
      }
      if (!this.recordingProgress.isMenuVisible()) { this.showRecordingMenu() }
      clearTimeout(this.recordingTimer)
      // If new progress payload is not received within 8s, hide polyline
      this.recordingTimer = setTimeout(() => { this.removeRecordingProgress() }, 8000)
      $('#mower-icon').css('opacity', '0.6')
    } else if (content.isRecording == false) {
      this.removeRecordingProgress()
    }
  }

  removeRecordingProgress() {
    this.hideRecordingMenu()
    this.recordingProgress?.hide()
    this.recordingProgress = null
    $('#mower-icon').css('opacity', '')
  }

  showRecordingMenu() {
    $('#mapping-menu').addClass('d-none')
    this.recordingProgress.menuElement().removeClass('d-none')
    if (this.recordingProgress.type == 'record_and_repeat') {
      $('#mapping-menu-button').html(`<i class="bi bi-record-circle text-danger" id="record-icon" style="font-size:20px"></i>`)
    }
  }

  hideRecordingMenu() {
    this.recordingProgress?.menuElement()?.addClass('d-none')
    if (this.recordingProgress?.type == 'record_and_repeat') {
      $('#mapping-menu-button').html(`<i class='bi bi-plus-circle'></i>`)
    }
  }

  hideGoTo() {
    if ($('#go-to-menu').is(":visible")) {
      $('#go-to-menu').addClass('d-none')
      $('#go-to-lat-lng').html('')
      $('#go-to-location').addClass('disabled')
      this.goToMarker?.setMap(null)
      google.maps.event.removeListener(this.goToListener)
    }
  }

  addListeners() {
    // Disable GoTo button if job is record and repeat
    $(window).on("map-rendered new-job", function (event, googleMap) {
      if (googleMap.currentJob.isRecordAndRepeat) {
        $('#go-to-button').parent().addClass('d-none').removeClass('d-flex')
        $('#go-to-button-disabled').parent().removeClass('d-none').addClass('d-flex')
      } else {
        $('#go-to-button').parent().removeClass('d-none').addClass('d-flex')
        $('#go-to-button-disabled').parent().addClass('d-none').removeClass('d-flex')
      }
    })

    // Send 'map-keep-out-zone' or 'record-trajectory-start' commands to the mower when the corresponding button is pressed.
    // Display response from the mower if the command was not successful.
    $('#map-keep-out, #start-record-repeat').on('click', (e) => {
      let _this = this
      if (companion.isJobInProgress && _this.isTargetIdMapKeepOut(e.target.id) && !confirm("Are you sure you want to add a Keep-Out Zone? All current mowing progress will be lost.")) {
        return
      }
      if (map.currentJob.jobId || e.target.id == 'start-record-repeat') {
        $("#sending-command").addClass('show').html(`<div class="spinner-border mx-3" role="status" style="width:25px;height:25px"></div><div>Sending command...</div>`)
        let command = _this.isTargetIdMapKeepOut(e.target.id) ? 'map-keep-out-zone' : 'record-trajectory-start'
        this.sendAjaxCommand(command)
      } else if (_this.isTargetIdMapKeepOut(e.target.id)) {
        confirm("Please create or load a Job on the mower before adding a Keep-Out Zone")
      }
    })

    $('#go-to-button').on('click', () => {
      if (!$('#go-to-menu').hasClass('d-none')) {
        this.hideGoTo()
        // Only open GoTo menu if there is no recording in progress
      } else if ($('.recording-progress').length == $('.recording-progress.d-none').length) {
        $('#mapping-menu').addClass('d-none')
        $('#go-to-menu').removeClass('d-none')

        this.goToListener = map.gmap.addListener("click", (mapsMouseEvent) => {
          $('#go-to-location').removeClass('disabled')
          this.goToMarker?.setMap(null)
          this.goToMarker = new google.maps.Marker({
            position: mapsMouseEvent.latLng,
            map: map.gmap,
          })
          this.goToMarker.setMap(map.gmap)
          if (google.maps.geometry.poly.containsLocation(mapsMouseEvent.latLng, map.currentJob.polygon)) {
            $('#go-to-location').removeClass('disabled')
            $('#go-to-lat-lng').html(`<pre class='text-center text-success mt-0 mb-1'>${mapsMouseEvent.latLng.lat().toFixed(8)}, ${mapsMouseEvent.latLng.lng().toFixed(8)}</pre>`)
          } else {
            $('#go-to-location').addClass('disabled')
            $('#go-to-lat-lng').html(`<div class='text-center text-danger mt-0 mb-1'>Pin must be within job perimeter.</div>`)
          }
          // Remove previous listeners for button clicks
          $('#go-to-location').off('click')
          $('#go-to-location').on('click', () => {
            $("#sending-command").addClass('show').html(`<div class="spinner-border mx-3" role="status" style="width:25px;height:25px"></div><div>Sending command...</div>`)
            $.ajax({
              type: 'POST',
              url: `/jobs/go_to`,
              data: { mower_id: location.pathname.split('/')[2], lat: mapsMouseEvent.latLng.lat(), lng: mapsMouseEvent.latLng.lng() },
              success: (response) => { $("#sending-command").removeClass('show') },
              error: (e) => {
                $("#sending-command").removeClass('show')
                console.debug(`${e.responseText} - ${e.status}: ${e.statusText}`)
              }
            })
          })
        })
      }
    })

    $('#go-to-button-disabled').on('click', () => {
      confirm("GoTo is not currently available for Record and Repeat jobs")
    })

    $('#go-to-close').on('click', () => {
      this.hideGoTo()
    })

    $('#mow-button').on('click', () => {
      $('#mow-confirmation').show()
    })

    $('#close-mow-confirmation').on('click', () => {
      $('#mow-confirmation').hide()
    })

    // When mow button is clicked or pressed, show progress bar and wait 2 seconds before sending command
    let pressTimer;
    $('#mow-button-confirmation').on('mousedown touchstart', (event) => {
      event.preventDefault()
      $('.mow-button-border').show()
      pressTimer = setTimeout(() => {
        this.sendAjaxCommand('mow')
        $('.mow-button-border').hide()
        $('#mow-button').children().addClass('d-none')
        $('#mow-confirmation').hide()
      }, 2000)
    }).on('mouseup mouseleave touchend', function () {
      // Cancel the timer if the button is released early
      clearTimeout(pressTimer)
      $('.mow-button-border').hide()
    })

    $('#cancel-button').on('click', () => {
      this.sendAjaxCommand('cancel')
    })
  }

  // When a command button is pressed (mow/cancel/keep out/etc), send ajax request to MowersController#control action.
  // Show `Sending command...` banner while command is sending, and then show error response if not successful.
  sendAjaxCommand(command) {
    let _this = this
    $("#sending-command").addClass('show').html(`<div class="spinner-border mx-3" role="status" style="width:25px;height:25px"></div><div>Sending command...</div>`)

    $.ajax({
      type: 'POST',
      url: `/mowers/${location.pathname.split('/')[2]}/control`,
      data: { command: command },
      dataType: "json",
      headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },
      success: this.commandSuccessCallback.bind(this),
      error: function (e) { _this.commandError(e) },
    })
  }

  // Logic for handling response from mower. Note: success in this case refers to the ajax call, so if the mower returns
  // an error response, that is handled here. If the ajax requests fails, the error callback will be triggered.
  commandSuccessCallback(e) {
    if (e.response) {
      console.debug(e.response)
      let stdout = e.response.stdout.toLowerCase()
      if (stdout.includes('success: true') || stdout.includes('publishing')) {
        $("#sending-command").removeClass('show')
        $('#mapping-menu').addClass('d-none')
      } else if (e.response.stdout.toLowerCase().includes('success: false')) {
        this.commandError(e, `<div>${e.response.stdout.split('"')[1]}</div>`)
      }
    } else {
      this.commandError(e, e.message)
    }
  }

  commandError(e, message = 'An error occurred while sending command, please try again or contact Greenzie Support.') {
    console.debug(`${e.responseText} - ${e.status}: ${e.statusText}`)
    $("#sending-command").removeClass('show')
    $("#loading-error").html(message).addClass('show')
    setTimeout(() => { $("#loading-error").removeClass('show') }, 8000)
  }

  isTargetIdMapKeepOut(targetId) {
    return targetId == 'map-keep-out'
  }
}
